import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { clientDetails } from "../../data/client";


const ClientDetails = (props) => {
    const { image } = props;
    return (
        <Card className="single-clients" >
            <Card.Img src={'assets/img/' + image} className="img-fluid"/>
        </Card>
    );
};

const options = {
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 15,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    loop: true,
    nav: false,
    dots: false,
    responsive: {
        300: {
            items: 2,
        },
        480: {
            items: 3,
        },
        768: {
            items: 4,
        },
        1170: {
            items: 6,
        }
    }
};
const BlogSingle = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>Blog Single</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/blog-single">&nbsp;Blog Single</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* Blog */}
            <Card id="blog-single" className="section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <div className="single-news">
                                <Card.Header className="news-head">
                                    <Card.Img src="assets/img/single-b1.jpg" />
                                </Card.Header>
                                <Card.Body className="news-body">
                                    <h3 className="date">25<span>nov</span></h3>
                                    <h2><Card.Link href="#"> give us that</Card.Link></h2>
                                    <Card.Title className="title">
                                        <span className="user"><i className="fa fa-user" aria-hidden="true" />
                                            adminaccess</span> <span className="comment">
                                            <i className="fa fa-comment-o" aria-hidden="true" />no comment
                                        </span>
                                    </Card.Title>
                                    <Card.Text>Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et  </Card.Text>
                                    <Card.Text>eti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor i do eiusmod tempo </Card.Text>
                                    <Card.Text>sum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisici ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et  </Card.Text>
                                    <h5 className="tags">tags-
                                        <li><Card.Link href="#">html,</Card.Link></li>
                                        <li><Card.Link href="#">Bootstrap,</Card.Link></li>
                                        <li><Card.Link href="#">Creative,</Card.Link></li>
                                        <li><Card.Link href="#">web design</Card.Link></li>
                                    </h5>
                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Newslatter */}

            {/* Clients Area */}
            <Card id="clients" className="clients section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <OwlCarousel {...options}>
                                {clientDetails.map(client => <ClientDetails key={`client-${client.id}`} {...client} />)}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Clients Area */}

        </>
    )
}

export default BlogSingle