import React from 'react';
import Blog from './Blog';
import Client from './Client';
import Contact from './Contact';
import Feature from './Feature';
import FunFacts from './FunFacts';
import Map from './MapContainer';
import PricingTable from './PricingTable';
import Service from './Service';
import Slider from './Slider';
import Team from './Team';
import Testimonial from './Testimonial';
import Work from './Work';

const Home = () => {
    return (
        <>
            <Slider />
            <Feature />
            <Service />
            <Work />
            <Testimonial />
            <Blog />
            <FunFacts />
            <Team />
            <PricingTable />
            <Map />
            <Contact />
            <Client />
        </>
    )
}

export default Home

