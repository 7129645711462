import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { priceDetails } from "../../data/price";

const PriceDetails = (props) => {
    const { amount } = props;
    return (
        <Col xs={12} sm={4} md={4}>
            <Card className="single-table">
                {/* Table Head */}
                <Card.Header className="table-head">
                    <div className="title-head">
                        <h2 className="title">Basic</h2>
                    </div>
                    <div className="price">
                        <Card.Text className="amount"><span>$</span>{amount}</Card.Text>
                    </div>
                </Card.Header>
                {/* Table List */}
                <ul className="table-list">
                    <li>Responsive( Mobile Website)</li>
                    <li>24/7 Technic Support</li>
                    <li>1 Year Hosting Free</li>
                    <li>Image slider (Animation)</li>
                    <li>Unlimited Email Account</li>
                    <li>Free Domain Name</li>
                </ul>
                <Card.Footer className="button-head">
                    <div className="button">
                        <span />
                        <Card.Link href="#" className="btn"><i className="fa fa-shopping-cart" aria-hidden="true" />buy now</Card.Link>
                    </div>
                </Card.Footer>
                {/* Table Bottom */}
            </Card>
        </Col>
    );
};
const PricingTable = () => {
    return (
        <>
            {/* Pricing Table */}
            <Card id="PricingTable" className="p-table4 section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>PRICING TABLE</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {priceDetails.map(price => <PriceDetails key={`price-${price.id}`} {...price} />)}
                    </Row>
                </Container>
            </Card>
            {/*/ End Pricing Table */}
        </>
    );
};

export default PricingTable;