import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { featuresDetails } from "../../data/feature";

const FeatureDetails = (props) => {
    const { title, description, icon } = props;
    return (
        <div className="single-right">
            <i className={icon} aria-hidden="true" />
            <div className="right-content">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

const Feature = () => {

    return (
        <>
            {/* features Area */}
            <Card id="features" className="section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>Our Feautes</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12" md="6" className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <Card.Body className="feature-left">
                                <Card.Img src="assets/img/computer.jpg" className="img-fluid" />
                            </Card.Body>
                        </Col>
                        <Col xs="12" sm="12" md="6" className="wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <Card className="feature-right">
                                {featuresDetails.map(feature => <FeatureDetails key={`features-${feature.id}`} {...feature} />)}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End features Area */}
        </>
    );
};

export default Feature;