import React, { useCallback, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Particles from "react-tsparticles";
import { TypeAnimation } from "react-type-animation";
import { loadFull } from "tsparticles";

const Slider = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      {/* Slider Area */}
      <Card id="slider">
        <Card.Body
          className="single-slider"
          style={{ backgroundImage: 'url("assets/img/slider1.jpg")' }}
        >
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              particles: {
                number: {
                  value: 400,
                  density: {
                    enable: true,
                    value_area: 2000,
                  },
                },
                color: {
                  value: "#ffffff",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.3286994724774322,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 4,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 500,
                  color: "#ffffff",
                  opacity: 0.6012795228245711,
                  width: 2,
                },
                move: {
                  enable: true,
                  speed: 2,
                  direction: "top-right",
                  random: false,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onHover: {
                    enable: true,
                    mode: "bubble",
                  },
                  onClick: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    lineLinked: {
                      opacity: 0.5,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 4,
                    duration: 0.3,
                    opacity: 1,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />

          <Container>
            <Row>
              <Col md="12">
                <Card.Title className="text">
                  <h1 className="cd-headline clip is-full-width">
                    <div>
                      awesome creative&nbsp;
                      <TypeAnimation
                        cursor={true}
                        className={"type"}
                        sequence={[
                          "design",
                          2000,
                          "Theme",
                          2000,
                          "codes",
                          2000,
                        ]}
                        speed={25}
                        deletionSpeed={25}
                        repeat={Infinity}
                      />
                    </div>
                  </h1>
                  <Card.Text>
                    Lorem Ipsum is simply dummy text of the printing and
                    printing and typesetting industry.
                    <br /> Lorem Ipsum has been the industry's
                  </Card.Text>
                  <Card.Footer className="button-head">
                    <div className="button">
                      <span></span>
                      <Card.Link href="#" className="btn">
                        Load More
                      </Card.Link>
                    </div>
                  </Card.Footer>
                </Card.Title>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      {/*/ End Slider Area */}
    </>
  );
};

export default Slider;
