import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

const MailSuccess = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>mail success</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/blog-archive">&nbsp;Mail Success</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* mail-success Page */}
            <Card className="mail-success section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <Card.Body className="mail-head">
                                <Card.Img src="assets/img/success.png" />
                                <h2><span><Card.Img src="assets/img/tic.png" alt="#" /></span>Your Mail Has Sent Successfully!</h2>
                                <Card.Footer className="button-head">
                                    <Card.Text className="button">
                                        <span />
                                        <Card.Link href="#" className="btn">Go Back</Card.Link>
                                    </Card.Text>
                                </Card.Footer>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card >
            {/* End mail-success Page */}
        </>
    )
}

export default MailSuccess

