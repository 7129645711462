import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Footer from '../commonPages/Footer';
import Header from '../commonPages/Header';

const Layout = () => {

    const [isActive, setActive] = useState(false);
    const divColor = document.getElementById('colors');

    const handleColorPlateToggle = () => {
        setActive(!isActive);
    };
    const handleColor1 = () => {
        divColor.setAttribute('href', "assets/css/color/color1.css");
    };
    const handleColor2 = () => {
        divColor.setAttribute('href', "assets/css/color/color2.css");
    };
    const handleColor3 = () => {
        divColor.setAttribute('href', "assets/css/color/color3.css");
    };
    const handleColor4 = () => {
        divColor.setAttribute('href', "assets/css/color/color4.css");
    };
    const handleColor5 = () => {
        divColor.setAttribute('href', "assets/css/color/color5.css");
    };
    const handleColor6 = () => {
        divColor.setAttribute('href', "assets/css/color/color6.css");
    };
    const handleColor7 = () => {
        divColor.setAttribute('href', "assets/css/color/color7.css");
    };
    const handleColor8 = () => {
        divColor.setAttribute('href', "assets/css/color/color8.css");
    };
    const handleColor9 = () => {
        divColor.setAttribute('href', "assets/css/color/color9.css");
    };

    return (
        <>
            <div className={isActive ? 'color-plate active' : 'color-plate'} >
                <Link to="#" className="icon" onClick={handleColorPlateToggle}>
                    <i className="fa fa-cog fa-spin" />
                </Link>
                <div className="color-head">
                    <span className="color1" onClick={handleColor1} />
                    <span className="color2" onClick={handleColor2}/>
                    <span className="color3" onClick={handleColor3}/>
                    <span className="color4" onClick={handleColor4}/>
                    <span className="color5" onClick={handleColor5}/>
                    <span className="color6" onClick={handleColor6}/>
                    <span className="color7" onClick={handleColor7}/>
                    <span className="color8" onClick={handleColor8}/>
                    <span className="color9" onClick={handleColor9}/>
                </div>
            </div>
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        </>
    );
};

export default Layout;