import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { Component } from "react";

export class MapContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false
   }
  }

  onMarkerClick = (props, marker) => {
      this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
  };

  onInfoWindowClose = () => { 
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    })};

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  render() {
    if (!this.props.loaded) return <div>Loading...</div>;

    return (
      <div id="map" style={{ height: "400px", position: "relative", width: "100%" }}      >
        <Map
          google={this.props.google}
          onClick={this.onMapClicked}
          initialCenter={{ lat: 22.933046, lng: 90.827027 }}
          zoom={4}
        >
          <Marker
            name="Welcome To Clipping Hunt"
            onClick={this.onMarkerClick}
            position={{ lat: 22.933046, lng: 90.827027 }}
          />

          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h4>{this.state.selectedPlace.name}</h4>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: ""
})(MapContainer);
