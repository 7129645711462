import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { clientDetails } from "../../data/client";


const ClientDetails = (props) => {
    const { image } = props;
    return (
        <Card className="single-clients" >
            <Card.Img src={'assets/img/' + image} />
        </Card>
    );
};

const options = {
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 15,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    loop: true,
    nav: false,
    dots: false,
    responsive: {
        300: {
            items: 2,
        },
        480: {
            items: 3,
        },
        768: {
            items: 4,
        },
        1170: {
            items: 6,
        }
    }
};

const Client = () => {
    return (
        <>
            {/* Clients Area */}
            <Card id="clients" className="clients section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <OwlCarousel {...options}>
                                {clientDetails.map(client => <ClientDetails key={`client-${client.id}`} {...client} />)}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Clients Area */}
        </>
    );
};

export default Client;