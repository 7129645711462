import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const StickyNavbar = () => {

    const [name, setName] = useState('');

    useEffect(() => {
        // var element = document?.getElementsByClassName(name);
        // element[0]?.classList.add('current');
        var header = document.getElementById("nav");
        var btns = header.getElementsByClassName("liNav");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document?.getElementsByClassName("current");
                current[0].className = current[0].className.replace(" current", "");
                this.className += " current";
            });
        }
    }, [name]);

    return (
        <>
            <div className="navbar navbar-default">
                <Navbar.Collapse className='collapse navbar-collapse'>
                    <Nav id="nav" className="nav navbar-nav" defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li" className='liNav current'>
                            <HashLink smooth to='/#slider' onClick={() => setName('Home')}>Home</HashLink>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to='/#Services' onClick={() => setName('Services')}>Services</HashLink>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to="/#latest-works" onClick={() => setName('Portfolio')}>
                                Portfolio<i className="fa fa-caret-down" aria-hidden="true"></i></HashLink>
                            <Nav.Item as="ul" id="ul1" className='dropdown'>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="/portfolio">Portfolio</Link>
                                </Nav.Item>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="/portfolio-single">portfolio-single</Link>
                                </Nav.Item>
                            </Nav.Item>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to="/#blog" onClick={() => setName('Blogs')}>Blogs<i className="fa fa-caret-down" aria-hidden="true"></i></HashLink>
                            <Nav.Item as="ul" id="ul2" className='dropdown'>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="/blog-archive">Blog Archive</Link>
                                </Nav.Item>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="/blog-single">Blog Single</Link>
                                </Nav.Item>
                            </Nav.Item>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to="/#team" onClick={() => setName('Team')}>Team</HashLink>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to="/#" onClick={() => setName('Pages')}>Pages<i className="fa fa-caret-down" aria-hidden="true"></i></HashLink>
                            <Nav.Item as="ul" id="ul3" className='dropdown'>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="404">Error 404</Link>
                                </Nav.Item>
                                <Nav.Item as="li" className='liNav'>
                                    <Link to="mail-success">Mail Success</Link>
                                </Nav.Item>
                            </Nav.Item>
                        </Nav.Item>
                        <Nav.Item as="li" className='liNav'>
                            <HashLink smooth to="/#contact" onClick={() => setName('Contact')}> Contact</HashLink>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </>
    );
};

export default StickyNavbar;