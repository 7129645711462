import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import StickyNavbar from "./StickyNavbar";


const Header = () => {


    return (
        <>
            <Navbar id="header" className="header" style={{"marginBottom":"0px"}}>
                <div className='header-inner'>
                    <Container>
                        <Row>
                            <Col xs="2" sm="2" md="3" >
                                <div className="logo">
                                    <Link to="/">
                                        <Image src="assets/DigiLogo.png" style={{maxWidth:'40%'}} onClick={() => window.location.href='/'}/>
                                    </Link>
                                </div>
                            </Col>
                            <Col xs="10" sm="10" md="9" >
                                <div className="mobile-menu" />
                                <StickyNavbar />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Navbar>
        </>
    );
};

export default Header;