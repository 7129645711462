import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WOW from 'wowjs';
import BlogArchive from './components/userLayout/BlogArchive';
import BlogSingle from './components/userLayout/BlogSingle';
import Error404 from './components/userLayout/Error404';
import Home from './components/userLayout/Home';
import Layout from './components/userLayout/Layout';
import MailSuccess from './components/userLayout/MailSuccess';
import Portfolio from './components/userLayout/Portfolio';
import PortfolioSingle from './components/userLayout/PortfolioSingle';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App() {

  useEffect(() => {
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      mobile: false,
      offset: 0,
      live: true,
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null
    }).init();
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/portfolio-single' element={<PortfolioSingle />} />
            <Route path='/blog-archive' element={<BlogArchive />} />
            <Route path='/blog-single' element={<BlogSingle />} />
            <Route path='/404' element={<Error404 />} />
            <Route path='/mail-success' element={<MailSuccess />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
