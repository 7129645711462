import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Contact = () => {
    return (
        <>
            {/*/ contact Area */}
            <Card id="contact" className="contact section" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <div className="contact-head">
                            <Col xs={12} sm={4} md={4}>
                                <Card.Body className="contact-left">
                                    <h3>Contact <span>us now</span></h3>
                                    <Card.Text className="p1">Aercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</Card.Text>
                                    <Card.Text>Lorem ipsum dolor sit amet consectetur adipiscing elitsed do eiusmod tempor incididunt utlabore et dolore magna aliqua.</Card.Text>
                                </Card.Body>
                            </Col>
                            <Col xs={12} sm={8} md={8}>
                                <Card.Body className="contact-right">
                                    <Row>
                                        <Form className="form" action="#">
                                            <Col md="6">
                                                <Form.Group>
                                                    <Form.Control name="name" type="text" placeholder="Full Name" as='input'/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control name="email" type="email" placeholder="Enter your email" as='input'/>
                                                </Form.Group>
                                                <Form.Group className="button">
                                                    <Button className="btn primary" type="submit" value="Input">Submit<i className="fa fa-paper-plane-o" aria-hidden="true" /></Button>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <Form.Control name="message" placeholder="type your message" as='textarea'/>
                                                </Form.Group>
                                            </Col>
                                        </Form>
                                    </Row>
                                    {/*/ End Contact Form */}
                                </Card.Body>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </Card>
            {/*/ End contact Area */}
        </>
    );
};

export default Contact;