import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <footer id="footer" className="footer section">
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col xs="12" sm="32" md="3" >
                                <div className="single-footer">
                                    <div className="logo">
                                        <Link to="/">
                                            <Image src="assets/DigiLogo.png" style={{ maxWidth: '40%' }} onClick={() => window.location.href = '/'} />
                                        </Link>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit,  consectetur adipisicing elit, </p>
                                    <div className="contact">
                                        <p className="location"><i className="fa fa-map-marker" aria-hidden="true" />United Kingdom</p>
                                        <p className="mail-add"><i className="fa fa-envelope-o" aria-hidden="true" />info@digi.com</p>
                                        <p className="call"><i className="fa fa-phone-square" aria-hidden="true" />call:900-200-2315</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" sm="32" md="3" >
                                <div className="single-footer">
                                    <h2><i className="fa fa-sliders" aria-hidden="true" />setra site map</h2>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />home</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />our shop</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />about us</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />blog</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />your account</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />our guarantee</span>
                                </div>
                            </Col>
                            <Col xs="12" sm="32" md="3" >
                                <div className="single-footer">
                                    <h2><i className="fa fa-cogs" aria-hidden="true" />our company</h2>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />shipping policy</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />international shipping</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />affiliates</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />our sponsors</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />team &amp; conditions</span>
                                    <span><i className="fa fa-caret-right" aria-hidden="true" />cash on delivery</span>
                                </div>
                            </Col>
                            <Col xs="12" sm="32" md="3" >
                                <div className="single-footer">
                                    <h2>newsletter subscription</h2>
                                    <p>subscribe to our newsletter to get allour news in your inbox</p>
                                    <div className="mail">
                                        <Form>
                                            <Form.Control type="email" placeholder="Enter your email" />
                                            <Button type="button" className="button">
                                                <Link to="#">subscribe us</Link>
                                            </Button>
                                            <i className="fa fa-lock" />
                                            <p>Your email address is 100% safe to us</p>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="copyright">
                    <Container>
                        <Row>
                            <Col xs="12" sm="6" md="6">
                                <div className="copyright-content">
                                    <p>Copyright 2023 | Developed by <Link to="/" target="_blank">Codeyes</Link></p>
                                </div>
                            </Col>
                            <Col xs="12" sm="6" md="6">
                                <ul className="social">
                                    <li><Card.Link href='#'><i className="fa fa-facebook" /></Card.Link></li>
                                    <li><Card.Link href='#'><i className="fa fa-twitter" /></Card.Link></li>
                                    <li><Card.Link href='#'><i className="fa fa-instagram" /></Card.Link></li>
                                    <li><Card.Link href='#'><i className="fa fa-linkedin" /></Card.Link></li>
                                    <li><Card.Link href='#'><i className="fa fa-youtube" /></Card.Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    );
};

export default Footer;