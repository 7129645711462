import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { servicesDetails } from "../../data/services";

const ServicesDetails = (props) => {
    const { title, delay, icon, description } = props;
    return (
        <Col xs={12} sm={6} md={4} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card className="single-service">
                <i className={icon} aria-hidden="true" />
                <h3>{title}</h3>
                <p>{description}</p>
            </Card>
        </Col>
    );
};
const Service = () => {
    return (
        <>
            {/* Start Service area */}
            <Card id="Services" className="section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>Exclusive <span>Services</span></h2>
                                <Card.Text>
                                    We create exclusive & creative Stuffs for our awesome clients
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {servicesDetails.map(service => <ServicesDetails key={`service-${service.id}`} {...service} />)}
                    </Row>
                </Container>
            </Card>
            {/* End Service */}
        </>
    );
};

export default Service;