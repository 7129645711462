const clientDetails = [
    { id: 1, image:"client1.jpg" },
    { id: 2, image:"client2.jpg" },
    { id: 3, image:"client3.jpg" },
    { id: 4, image:"client4.jpg" },
    { id: 5, image:"client5.jpg" },
    { id: 6, image:"client6.jpg" },
    { id: 8, image:"client1.jpg" },
    { id: 9, image:"client2.jpg" },
    { id: 10, image:"client3.jpg" }
];

export {
    clientDetails
};