import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { teamDetail } from "../../data/team";

const TeamDetail = (props) => {
    const { delay, image, teamMemberName, teamWork } = props;
    return (
        <Col xs={12} sm={6} md={3} className="wow fadeInRight" data-wow-duration="0.8s" data-wow-delay={delay}>
            {/* Single Team */}
            <Card className="single-team">
                <Card.Header className="team-head">
                    <Card.Img src={'assets/img/' + image} className="img-fluid" />
                    <div className="team-hover">
                        <ul className="social">
                            <li><Card.Link href="#"><i className="fa fa-facebook"></i></Card.Link></li>
                            <li><Card.Link href="#"><i className="fa fa-twitter"></i></Card.Link></li>
                            <li><Card.Link href="#"><i className="fa fa-dribbble"></i></Card.Link></li>
                            <li><Card.Link href="#"><i className="fa fa-linkedin"></i></Card.Link></li>
                        </ul>
                    </div>
                </Card.Header>
                <Card.Body className="t-name">
                    <h4>{teamMemberName}<span>{teamWork}</span></h4>
                </Card.Body>
            </Card>
            {/*/ End Single Team */}
        </Col>
    );
};

const Team = () => {
    return (
        <>
            {/* Team */}
            <Card id="team" className="section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>our creative team</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {teamDetail.map(team => <TeamDetail key={`team-${team.id}`} {...team} />)}
                    </Row>
                </Container>
            </Card>
            {/*/ End Team */}

        </>
    );
};

export default Team;