import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { funfactsDetails } from "../../data/funfacts";


const FunfactsDetail = (props) => {
    const { title, delay, icon, count } = props;
    return (
        <Col xs={12} sm={6} md={3} className="wow fadeIn" data-wow-duration="0.8s" data-wow-delay={delay}>
            {/* Single Fun */}
            <Card className="single-fun">
                <i className={icon} aria-hidden="true" />
                <CountUp end={count} redraw={true}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef}></span>
                        </VisibilitySensor>
                    )}
                </CountUp>
                <Card.Text>{title}</Card.Text>
            </Card>
            {/* / End Single Fun */}
        </Col>
    );
};
const FunFacts = () => {
    return (
        <>
            {/* Fun Facts Area */}
            <Card id="fun-facts" className="fun-facts section" data-stellar-background-ratio="0.5">
                <div className="bf-color">
                    <Container>
                        <Row>
                            {funfactsDetails.map(fun => <FunfactsDetail key={`fun-${fun.id}`} {...fun} />)}
                        </Row>
                    </Container>
                </div>
            </Card>
            {/*/ End Fun Facts Area */}
        </>
    );
};

export default FunFacts;