const teamDetail = [
    { id: 1, delay:"0.7s", image:"team1.png", teamMemberName:"naimur rahman", teamWork:"Branding"  },
    { id: 2, delay:"0.6s", image:"team2.png", teamMemberName:"rakib hassan", teamWork:"Designer"  },
    { id: 3, delay:"0.5s", image:"team3.png", teamMemberName:"afsana mimi", teamWork:"Photographer"  },
    { id: 4, delay:"0.4s", image:"team1.png", teamMemberName:"saiful abir", teamWork:"Developer"  },

];

export {
    teamDetail
};