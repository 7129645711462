import Isotope from 'isotope-layout';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { clientDetails } from "../../data/client";
import { workDetails } from "../../data/latestworks";
import PortfolioDetail from './PortfolioDetail';

const ClientDetails = (props) => {
    const { image } = props;
    return (
        <Card className="single-clients" >
            <Card.Img src={'assets/img/' + image} />
        </Card>
    );
};

const options = {
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 15,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    loop: true,
    nav: false,
    dots: false,
    responsive: {
        300: {
            items: 2,
        },
        480: {
            items: 3,
        },
        768: {
            items: 4,
        },
        1170: {
            items: 6,
        }
    }
};

// const PortfolioDetail = (props) => {
//     const { workclass, image } = props;
//     return (
//         <Col xs={12} sm={4} md={4} className={'filter-item ' + workclass}>
//             {/* Single Works */}
//             <Card className="single-work">
//                 <Card.Img src={'assets/img/' + image} fluid />
//                 <div className="works-hover">
//                     <Card.Link href="/portfolio-single">
//                         <h4>Project Name</h4>
//                     </Card.Link>
//                     <Card.Link className="link popup" href={'assets/img/' + image}>
//                         <i className="fa fa-picture-o" aria-hidden="true"></i>
//                     </Card.Link>
//                     <Card.Link href="/portfolio-single">
//                         <i className="fa fa-link" aria-hidden="true"></i>
//                     </Card.Link>
//                 </div>
//             </Card>
//             {/* / End Single Works */}
//         </Col>
//     );
// };

const Portfolio = () => {

    const [isotope, setIsotope] = React.useState(null);
    const [filterKey, setFilterKey] = React.useState('*');

    useEffect(() => {
        setIsotope(
            new Isotope('.filter-container', {
                itemSelector: '.filter-item', // filter-item: className of the isotope elements
                layoutMode: 'fitRows', // for horizontal isotope
            })
        );
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        if (isotope) {
            // sanity check
            filterKey === '*'
                ? isotope.arrange({ filter: `*` })
                : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);



    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>Our Portfolio</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/portfolio">&nbsp;Portfolio</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* Latest Works Area */}
            <Card id="latest-works" className="latest-works section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>Our Works</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.                                 </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {/* Project Nav */}
                            <Card.Body className="works-menu wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                {/* <ul>
                                    <li className="active" data-filter="*"></li>
                                    {workMenu.map(work => <li key={`work-${work.id}`} data-filter={work.filter}>{work.title}</li>)}
                                </ul> */}
                                <ul>
                                    <li className={filterKey === '*' ? 'active' : ''} onClick={() => setFilterKey('*')}>All Works</li>
                                    <li className={filterKey === 'print' ? 'active' : ''} onClick={() => setFilterKey('print')}>Print</li>
                                    <li className={filterKey === 'identity' ? 'active' : ''} onClick={() => setFilterKey('identity')}>Identity</li>
                                    <li className={filterKey === 'branding' ? 'active' : ''} onClick={() => setFilterKey('branding')}>Branding</li>
                                    <li className={filterKey === 'web' ? 'active' : ''} onClick={() => setFilterKey('web')}>Web</li>
                                    <li className={filterKey === 'wordpress' ? 'active' : ''} onClick={() => setFilterKey('wordpress')}>Wordpress</li>
                                </ul>
                            </Card.Body>
                            {/* / End Project Nav */}
                        </Col>
                    </Row>
                    <Row>
                        <div className="filter-container">
                            {workDetails.map(work => <PortfolioDetail key={`work-${work.id}`} {...work} />)}
                        </div>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card.Footer className='button-head'>
                                <div className="button">
                                    <span></span>
                                    <Card.Link href='#' className="btn">Load More</Card.Link>
                                </div>
                            </Card.Footer>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Works Area */}

            {/* Clients Area */}
            <Card id="clients" className="clients section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <OwlCarousel {...options}>
                                {clientDetails.map(client => <ClientDetails key={`client-${client.id}`} {...client} />)}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Clients Area */}
        </>
    );
};

export default Portfolio;