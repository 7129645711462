import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { testimonialDetails } from "../../data/testimonials";

const TestimonialDetail = (props) => {
    const {image,description,clientName } = props;
    return (
        <Card className="single-testimonial">
            <Card.Img src={'assets/img/' + image} className="img-fluid"/>
            <h2><i>What Clients  Says</i></h2>
            <Card.Text><i className="fa fa-quote-left" aria-hidden="true" /><i>{description}<i className="fa fa-quote-right" aria-hidden="true" /></i></Card.Text>
            <span> -{clientName}</span>
        </Card>
    );
};

const options = {
    items: 1,
    autoplay: true,
    autoplayTimeout: 3500,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    loop: true,
    merge: true,
    nav: false,
    dots: true,
};

const Testimonial = () => {
    return (
        <>
            {/* Testimonials */}
            <Card id="testimonials" className="section" data-stellar-background-ratio="0.5">
                <section className="bg">
                    <Container>
                        <Row>
                            <Col md={12} sm={12} xs={12}>
                                <OwlCarousel {...options}>
                                    {testimonialDetails.map(testimonial => <TestimonialDetail key={`testimonial-${testimonial.id}`} {...testimonial} />)}
                                </OwlCarousel>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Card>
            {/*/ End Testimonials */}
        </>
    );
};

export default Testimonial;