
const workMenu = [
    { id: 1, title: "Print", filter: ".print" },
    { id: 2, title: "Identity", filter: ".identity" },
    { id: 3, title: "Branding", filter: ".branding" },
    { id: 4, title: "Web", filter: ".web" },
    { id: 5, title: "Wordpress", filter: ".wordpress" }
];


const workDetails = [
    { id: 1, workclass:"print identity", image:"assets/img/pf1.jpg" },
    { id: 2, workclass:"wordpress branding", image:"assets/img/pf2.jpg" },
    { id: 3, workclass:"web", image:"assets/img/pf3.jpg" },
    { id: 4, workclass:"wordpress", image:"assets/img/pf4.jpg" },
    { id: 5, workclass:"branding", image:"assets/img/pf5.jpg" },
    { id: 6, workclass:"web print", image:"assets/img/pf6.jpg" }
];

export {
    workMenu,
    workDetails
};