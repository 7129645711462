import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { blogDetails } from "../../data/blog";

const BlogDetails = (props) => {
    const { image, delay, date, month, description } = props;
    return (
        <Col xs={12} sm={4} md={4} className='wow fadeIn' data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card className="single-news">
                <Card.Header className="news-head">
                    <Card.Img src={'assets/img/' + image} className="img-fluid" />
                </Card.Header>
                <Card.Body className="news-body">
                    <h3 className="date">{date}<span>{month}</span></h3>
                    <h2><Card.Link href="/blog-single"> give us that</Card.Link></h2>
                    <Card.Title className="title">
                        <span className="user">
                            <i className="fa fa-user" aria-hidden="true"></i>adminaccess
                        </span>
                        <span className="comment"><i className="fa fa-comment-o" aria-hidden="true"></i>no comment
                        </span>
                    </Card.Title>
                    <Card.Text>{description}</Card.Text>
                    <Card.Link href="/blog-single" className="btn">Read More<i className="fa fa-caret-right" aria-hidden="true"></i></Card.Link>
                </Card.Body>
            </Card>
        </Col>
    );
};
const Blog = () => {
    return (
        <>

            {/* Blog */}
            <Card id="blog" className="section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>latest News</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.                                 </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {blogDetails.map(blog => <BlogDetails key={`blog-${blog.id}`} {...blog} />)}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card.Footer className='button-head'>
                                <div className="button">
                                    <span></span>
                                    <Card.Link href='#' className="btn">Load More</Card.Link>
                                </div>
                            </Card.Footer>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Newslatter */}

        </>
    );
};

export default Blog;