import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { workDetails } from "../../data/latestworks";
import Viewer from 'react-viewer';


const WorkDetail = (props) => {
    const { workclass, image } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Col xs={12} sm={4} md={4} className={'filter-item ' + workclass}>
            {/* Single Works */}
            <Card className="single-work">
                <Card.Img src={image} className="img-fluid" />
                <div className="works-hover">
                    <Card.Link href="/portfolio-single">
                        <h4>Project Name</h4>
                    </Card.Link>
                    <Card.Link className="link" onClick={() => setIsOpen(true)}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                    </Card.Link>
                    {isOpen && <Viewer
                        visible={isOpen}
                        onClose={() => setIsOpen(false)}
                        drag={false}
                        zoomable={false}
                        scalable={false}
                        images={[{ src: image, alt: image }]}
                        noToolbar={true}
                        noNavbar={true}                        
                    />}
                    <Card.Link href="/portfolio-single">
                        <i className="fa fa-link" aria-hidden="true"></i>
                    </Card.Link>
                </div>
            </Card>
            {/* / End Single Works */}
        </Col>
    );
};

const Work = () => {

    const [isotope, setIsotope] = React.useState(null);
    const [filterKey, setFilterKey] = React.useState('*');

    useEffect(() => {
        setIsotope(
            new Isotope('.filter-container', {
                itemSelector: '.filter-item', // filter-item: className of the isotope elements
                layoutMode: 'fitRows', // for horizontal isotope
            })
        );
    }, []);

    useEffect(() => {

        if (isotope) {
            // sanity check
            filterKey === '*'
                ? isotope.arrange({ filter: `*` })
                : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);

    return (
        <>
            {/* Latest Works Area */}
            <Card id="latest-works" className="latest-works section">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card.Body className="section-title">
                                <h2>Latest Works</h2>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.                                 </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {/* Project Nav */}
                            <Card.Body className="works-menu wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                {/* <ul>
                                    <li className="active" data-filter="*"></li>
                                    {workMenu.map(work => <li key={`work-${work.id}`} data-filter={work.filter}>{work.title}</li>)}
                                </ul> */}
                                <ul>
                                    <li className={filterKey === '*' ? 'active' : ''} onClick={() => setFilterKey('*')}>All Works</li>
                                    <li className={filterKey === 'print' ? 'active' : ''} onClick={() => setFilterKey('print')}>Print</li>
                                    <li className={filterKey === 'identity' ? 'active' : ''} onClick={() => setFilterKey('identity')}>Identity</li>
                                    <li className={filterKey === 'branding' ? 'active' : ''} onClick={() => setFilterKey('branding')}>Branding</li>
                                    <li className={filterKey === 'web' ? 'active' : ''} onClick={() => setFilterKey('web')}>Web</li>
                                    <li className={filterKey === 'wordpress' ? 'active' : ''} onClick={() => setFilterKey('wordpress')}>Wordpress</li>
                                </ul>
                            </Card.Body>
                            {/* / End Project Nav */}
                        </Col>
                    </Row>
                    <Row>
                        <div className="filter-container">
                            {workDetails.map(work => <WorkDetail key={`work-${work.id}`} {...work} />)}
                        </div>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card.Footer className='button-head'>
                                <div className="button">
                                    <span></span>
                                    <Card.Link href='#' className="btn">Load More</Card.Link>
                                </div>
                            </Card.Footer>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Works Area */}
        </>
    );
};

export default Work;