import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import Viewer from 'react-viewer';

const PortfolioDetail = (props) => {
    const { workclass, image } = props;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Col xs={12} sm={4} md={4} className={'filter-item ' + workclass}>
            {/* Single Works */}
            <Card className="single-work">
                <Card.Img src={image} className="img-fluid" />
                <div className="works-hover">
                    <Card.Link href="/portfolio-single">
                        <h4>Project Name</h4>
                    </Card.Link>
                    <Card.Link className="link" onClick={() => setIsOpen(true)}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                    </Card.Link>
                    <Card.Link href="/portfolio-single">
                        <i className="fa fa-link" aria-hidden="true"></i>
                    </Card.Link>
                </div>
                {isOpen && <Viewer
                    visible={isOpen}
                    onClose={() => setIsOpen(false)}
                    drag={false}
                    zoomable={false}
                    scalable={false}
                    images={[{ src: image, alt: image }]}
                    noToolbar={true}
                    noNavbar={true}
                />}
            </Card>
            {/* / End Single Works */}
        </Col>
    );
};

export default PortfolioDetail

