const servicesDetails = [
    { id: 1, title: "Web Design",delay:"0.4s", icon:"fa fa-briefcase", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, " },
    { id: 2, title: "Web Development",delay:"0.5s", icon:"fa fa-lightbulb-o", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," },
    { id: 3, title: "Investment Plan",delay:"0.6s", icon:"fa fa-paper-plane", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," },
    { id: 4, title: "Finance Support",delay:"0.7s", icon:"fa fa-meetup", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," },
    { id: 5, title: "World Marketing",delay:"0.8s", icon:"fa fa-globe", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," },
    { id: 6, title: "Perfect Solution",delay:"0.9s", icon:"fa fa-clock-o", description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," },
];

export {
    servicesDetails
};