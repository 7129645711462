import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

const Error404 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>Error 404</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/blog-archive">&nbsp;Error 404</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* 404 Error Page */}
            <Card className="error section">
                <Container>
                    <Row>
                        <Col md={6} sm={6} xs={12}>
                            <Card.Body className="error-left">
                                <h2>404</h2>
                                <Card.Text><span>Opps!</span>Page Not Found!</Card.Text>
                                <Card.Text className="p2">Sorry the Page Could not be Found here.<br />Try using the button below to go to main page of the site</Card.Text>
                                <Card.Footer className="button-head">
                                    <Card.Text className="button">
                                        <span />
                                        <Card.Link href="#" className="btn">Go Back</Card.Link>
                                    </Card.Text>
                                </Card.Footer>
                            </Card.Body>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <Card.Body className="error-right">
                                <Card.Img className="img" src="assets/img/error-img.png" />
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card >
            {/* End 404 Error Page */}
        </>
    )
}

export default Error404