import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { blogDetails } from "../../data/blogdetail";
import { clientDetails } from "../../data/client";


const ClientDetails = (props) => {
    const { image } = props;
    return (
        <Card className="single-clients" >
            <Card.Img src={'assets/img/' + image} />
        </Card>
    );
};

const options = {
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 15,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    loop: true,
    nav: false,
    dots: false,
    responsive: {
        300: {
            items: 2,
        },
        480: {
            items: 3,
        },
        768: {
            items: 4,
        },
        1170: {
            items: 6,
        }
    }
};
const BlogDetails = (props) => {
    const { delay, title, date, month, image, description } = props;
    return (
        <Col xs={12} sm={12} md={12} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <div className="single-news">
                <Card.Header className="news-head">
                    <Card.Img src={"assets/img/" + image} />
                </Card.Header>
                <Card.Body className="news-body">
                    <h3 className="date">{date}<span>{month}</span></h3>
                    <h2><Card.Link href="#"> {title}</Card.Link></h2>
                    <Card.Title className="title">
                        <span className="user"><i className="fa fa-user" aria-hidden="true" />
                            adminaccess</span> <span className="comment">
                            <i className="fa fa-comment-o" aria-hidden="true" />no comment
                        </span>
                    </Card.Title>
                    <Card.Text>{description}</Card.Text>
                    <Card.Text>{description}</Card.Text>
                    <Card.Link href="#" className="btn">Read More<i className="fa fa-caret-right" aria-hidden="true"></i></Card.Link>
                    <h5 className="tags">tags-
                        <li><Card.Link href="#">html,</Card.Link></li>
                        <li><Card.Link href="#">Bootstrap,</Card.Link></li>
                        <li><Card.Link href="#">Creative,</Card.Link></li>
                        <li><Card.Link href="#">web design</Card.Link></li>
                    </h5>
                </Card.Body>
            </div>
        </Col>
    );
};
const BlogArchive = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>Blog</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/blog-archive">&nbsp;Blog</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* Blog */}
            <Card id="blog-single" className="section">
                <Container>
                    <Row>
                        {blogDetails.map(blog => <BlogDetails key={`blog-${blog.id}`} {...blog} />)}
                        <Col xs={12} sm={12} md={12}>
                            <Card.Header className="pagination-head">
                                <div className="pagination">
                                    <ul>
                                        <li><Card.Link href="#"><i className="fa fa-long-arrow-left" aria-hidden="true" />prev</Card.Link></li>
                                        <li><Card.Link href="#">1</Card.Link></li>
                                        <li><Card.Link href="#">2</Card.Link></li>
                                        <li><Card.Link href="#">3</Card.Link></li>
                                        <li><Card.Link href="#">4</Card.Link></li>
                                        <li><Card.Link href="#">5</Card.Link></li>
                                        <li><Card.Link href="#">next<i className="fa fa-long-arrow-right" aria-hidden="true" /></Card.Link></li>
                                    </ul>
                                </div>
                            </Card.Header>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Newslatter */}

            {/* Clients Area */}
            <Card id="clients" className="clients section">
                <Container>
                    <Row>
                        <Col md={12} sm={12} xs={12}>
                            <OwlCarousel {...options}>
                                {clientDetails.map(client => <ClientDetails key={`client-${client.id}`} {...client} />)}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Clients Area */}

        </>
    )
}

export default BlogArchive