import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel';
import { portfolioDetail } from "../../data/portfoliodetail";

const PortfolioDetail = (props) => {
    const { image, date, category, projecturl } = props;
    return (
        <Card className="single-news">
            <Card.Header className="news-head">
                <Card.Img src={"assets/img/"+image} alt="#" />
            </Card.Header>
            <Card.Body className="news-body">
                <h2>about project</h2>
                <Card.Text>Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, </Card.Text>
                <h5>project details</h5>
                <span><i className="fa fa-clock-o" aria-hidden="true" />date: {date}</span>
                <span><i className="fa fa-folder-open-o" aria-hidden="true" />category: {category}</span>
                <span><i className="fa fa-folder-o" aria-hidden="true" />project url: <Card.Link href="#">{projecturl}</Card.Link></span>
                <Card.Link href="#" className="btn">view live<i className="fa fa-caret-right" aria-hidden="true" /></Card.Link>
            </Card.Body>
        </Card>
    );
};

const options = {
    items: 1,
    autoplay: false,
    autoplayTimeout: 4500,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    margin: 15,
    loop: true,
    merge: true,
    nav: true,
    dots: false,
    navText: ['PREVIOUS', 'NEXT'],
};

const PortfolioSingle = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            {/*breadcrumb */}
            <Card className="breadcrumb" data-stellar-background-ratio="0.5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card.Body className="breadcrumb-text">
                                <h2>single portfolio</h2>
                                <ul>
                                    <li><Card.Link href="/">Home</Card.Link> <i className="fa fa-long-arrow-right" aria-hidden="true" /></li>
                                    <li><Card.Link href="/portfolio-single">&nbsp;Single Portfolio</Card.Link></li>
                                </ul>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End breadcrumb */}

            {/* single-pf */}
            <Card id="single-pf" className=" section">
                <Container>
                    <Row>
                        <Col md="12">
                            <OwlCarousel {...options}>
                                {portfolioDetail.map(psingle => <PortfolioDetail key={`psingle-${psingle.id}`} {...psingle} />)}
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End single-pf */}
        </>
    )
}

export default PortfolioSingle